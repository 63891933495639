import React, { FC, useState, useEffect } from 'react';
import { getRegisterHost } from './helper';

// import './noncrypto.less';

interface IProps {
  content: {
    copyright: string;
    termsOfService: string;
    privacyTerms: string;
  };
}

const DEFAULT_LINK = 'https://www.mufex.com/';

export const NoncryptoFooter: FC<IProps> = ({ content }) => {
  const [prefix, setPrefix] = useState(DEFAULT_LINK);

  useEffect(() => {
    const url = getRegisterHost();
    url && setPrefix(getRegisterHost());
  }, []);

  return (
    <div className="mufex-noncrypto-footer-wrapper">
      <footer className="mufex-noncrypto-footer">
        <div className="mufex-noncrypto-footer-logo" />
        <div className="mufex-noncrypto-footer-copyright">
          {content.copyright}
        </div>
        <div className="mufex-noncrypto-footer-desc-wrapper">
          <a
            href={`${prefix}/app/terms-service/information`}
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            {content.termsOfService}
          </a>
          &nbsp; | &nbsp;
          <a
            href={`${prefix}/app/terms-service/privacyPolicy`}
            rel="nofollow noopener noreferrer"
            target="_blank"
          >
            {content.privacyTerms}
          </a>
        </div>
      </footer>
    </div>
  );
};

export default NoncryptoFooter;

import { initBetterbitFrame } from '@better-bit-fe/betterbit-frame';
import { useUserInfo } from '@better-bit-fe/base-provider';
import { getLang, isMobile } from '@better-bit-fe/base-utils';

const useFrame = () => {
  const { componentHeader, componentFooter } = initBetterbitFrame({
    position: 'middle'
  });
  const { setUserInfo, userInfo } = useUserInfo();

  // 切换多语言
  const onLanguageChangeCallBack = (val) => {
    let newUrl: string;
    const langReg = /([a-z]{2}-[A-Z]{2})/;
    if (langReg.test(location.href)) {
      newUrl = location.href.replace(langReg, val);
    } else {
      newUrl = `${location.origin}/${val}${location.pathname}${location.search}`;
    }
    location.href = newUrl;
    localStorage.setItem('LANG_KEY', val);
  };
  // @ts-ignore
  const handleLogout = () => {
    setUserInfo();
  };

  componentHeader.then((res) => {
    const { onLanguageChange, onLogout, setReturnPage } = res;
    // if (isMobile()) {
    //   const url = `/${getLang()}/downloadApp/`;
    //   setReturnPage(url); //首页PC登录之后跳转到交易页，H5登录之后跳转到download页面
    // }

    onLanguageChange((lang) => {
      onLanguageChangeCallBack(lang);
    });
    onLogout(() => {
      handleLogout();
      window.location.reload();
    });
  });

  componentFooter.then((res) => {
    console.log('Footer Rendered');
  });
};

export default useFrame;

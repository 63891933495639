import { parse, stringifyUrl, StringifiableRecord } from 'query-string';

// fill object query to url
export function fillUrlParams(url: string, query: StringifiableRecord) {
  return stringifyUrl({ url, query });
}

/**
 * 
 * console.log(location.search);
//=> '?foo=bar'

const parsed = queryString.parse(location.search);
console.log(parsed);
//=> {foo: 'bar'}

console.log(location.hash);
//=> '#token=bada55cafe'

const parsedHash = queryString.parse(location.hash);
console.log(parsedHash);
//=> {token: 'bada55cafe'}
 * @param url 
 * @returns 
 */
export function parseUrl(url: string) {
  return parse(url);
}

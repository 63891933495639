export function getLang() {
  const navigatorSupportMap = {
    ko: 'ko-KR',
    en: 'en-US',
    zh: 'zh-TW',
    cn: 'zh-CN'
  };
  const defalueLang = 'en-US';
  if (typeof window !== 'undefined' && window) {
    //1. url上取
    const langReg = /([a-z]{2}-[A-Z]{2})/;
    let hrefLang = window.location.pathname.match(langReg);
    if (hrefLang) {
      // @ts-ignore
      hrefLang = hrefLang[0];
    }
    // 2.localstorage取
    const storageLang = localStorage.getItem('LANG_KEY');
    // 3.从系统语言上取
    const systemLang = navigatorSupportMap[navigator.language];
    // 4. 默认语言
    const lang = hrefLang || storageLang || systemLang || defalueLang;
    //获取到之后先重定向，再set保证其他页面也一致
    // @ts-ignore
    localStorage.setItem('LANG_KEY', lang);
    return lang;
  }
  return defalueLang;
}

export const langList = [
  {
    key: 'en-US',
    label: 'English'
  },
  {
    key: 'zh-TW',
    label: '繁体中文'
  },
  {
    key: 'ko-KR',
    label: '한국어'
  },
  {
    key: 'zh-CN',
    label: '简体中文'
  }
];

export const langKeyList = ['en-US', 'zh-TW', 'ko-KR', 'zh-CN'];

export const getGlobalLocaleMsg = () => {
  if (typeof window !== 'undefined') {
    let storeData = {};
    try {
      const innerText =
        document.getElementById('__NEXT_DATA__')?.innerText || '{}';
      storeData =
        innerText && JSON.parse(innerText)
          ? JSON.parse(innerText)?.props?.pageProps?.messages || {}
          : {};
    } catch (error) {
      console.log(error);
    }
    return storeData;
  }
  return {};
};

// @ts-nocheck
import React, { FC, useEffect } from 'react';
import clx from 'classnames';
import { isApp } from '@better-bit-fe/base-utils';

interface FooterProps {
  locale?: string;
  routeSelect?: (val: string) => void;
  className?: string;
  defaultContent?: string; // for SEO
}

const CONTAINER_ID = 'common-footer';

export const Footer: FC<FooterProps> = ({
  locale,
  routeSelect,
  className,
  defaultContent = ''
}) => {
  useEffect(() => {
    const wrapperEle = document.getElementById(CONTAINER_ID);
    if (wrapperEle && isApp()) {
      wrapperEle.style.display = 'none';
    }

    window &&
      // @ts-ignore
      window?.RegionFrame?.ready((uniframe) => {
        const { footer } = uniframe;
        footer.init({
          containerId: CONTAINER_ID
        });
        footer
          .setProperty('isGeneral', true)
          .subscribeEvent('routeSelect', routeSelect)
          .setProperty('locale', locale);
      });
  }, []);

  useEffect(() => {
    // @ts-ignore
    if (window?.RegionFrame?.footer) {
      // @ts-ignore
      window.RegionFrame.footer.setProperty('locale', locale);
    }
  }, [locale]);

  return (
    <div
      id={CONTAINER_ID}
      className={className}
      dangerouslySetInnerHTML={{ __html: defaultContent }}
    />
  );
};

export { NoncryptoFooter } from './noncrypto';
